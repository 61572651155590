import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import { DynamicStaticImage } from '../components/ArtistAtSchool/dynamicStaticImage'
import { schoolMeta, getSchoolArtistBySlug } from '../data/school-data'
import SchoolLayout from '../components/schoollayout'

const artistAtSchoolTemplate = ({ pageContext }) => {
  const { post } = pageContext
  const artist = getSchoolArtistBySlug(post)
  const image = artist.getImageLocalPath
  const youtubeVideoIds = artist.youtubeVideoIds
  const date = artist.date

  return (
    <SchoolLayout>
        <div className="!relative">
            <Helmet>
                <title>Le Festif! à l'École - {artist.name}</title>
                <meta name="description" content={schoolMeta.description} />
                <meta name="keywords" content={schoolMeta.keywords} />
                <meta property="og:title" content={schoolMeta.title} />
                <meta property="og:description" content={schoolMeta.description}/>
                <meta property="og:type" content="website" />
                <meta property="og:image" content={schoolMeta.ogImageUrl} />
            </Helmet>

            <StaticImage
                src="../images/festifAtSchool/artists_detail/bg.png"
                alt="Background image"
                className="!absolute top-0 h-[110%] w-full bg-beige-school mt-[-80px]" />

            {/* MENU */}
            {/* <div className="text-center bg-beige-school">
                <div className="!relative lg:h-[160px] h-[75px]">
                    <a className="!absolute lg:top-10 top-4 lg:left-10 left-4" href="/le-festif-a-l-ecole/">
                        <StaticImage
                            src="../images/festifAtSchool/ogo.png"
                            alt="Le Festif! à l'école logo"
                            className="lg:w-[308px] w-[70px]" />
                    </a>
                    <div className="text-right">
                        <a className="!absolute lg:top-10 top-4 lg:right-10 right-4" href="/">
                            <StaticImage
                            src="../images/festifAtSchool/logo_festif.png"
                            alt="Le Festif de Baie-Saint-Paul logo"
                            className="lg:w-[230px] w-[70px]" />
                        </a>
                    </div>
                </div>
            </div> */}

            <div className="!relative lg:max-w-[80%] max-w-[90%] lg:mx-[10%] mx-[5%] !pt-12">
                <center>
                    <div>
                        <a href={artist.facebook}>
                            <StaticImage
                                src="../images/festifAtSchool/artists_detail/facebook.png"
                                alt="Facebook logo"
                                className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2" />
                        </a>
                        <a href={artist.instagram}>
                            <StaticImage
                                src="../images/festifAtSchool/artists_detail/instagram.png"
                                alt="Instagram logo"
                                className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                        </a>
                        <a href={artist.spotify}>
                            <StaticImage
                                src="../images/festifAtSchool/artists_detail/spotify.png"
                                alt="Spotify logo"
                                className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                        </a>
                        <a href={artist.apple}>
                            <StaticImage
                                src="../images/festifAtSchool/artists_detail/apple.png"
                                alt="Apple logo"
                                className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                        </a>
                        <a href={artist.website}>
                            <StaticImage
                                src="../images/festifAtSchool/artists_detail/web.png"
                                alt="Generic web site logo"
                                className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                        </a>
                        <a href={artist.youtube}>
                            <StaticImage
                                src="../images/festifAtSchool/artists_detail/youtube.png"
                                alt="Generic web site logo"
                                className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                        </a>
                        <a href={artist.tiktok}>
                            <StaticImage
                                src="../images/festifAtSchool/artists_detail/tiktok.png"
                                alt="Generic web site logo"
                                className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                        </a>
                    </div>
                    <p className="font-poppins font-black lg:text-[79px] text-[40px] text-blue-school uppercase">{artist.name.toUpperCase()}</p>

                    <DynamicStaticImage
                        localPath={image}
                        alt={artist.name}
                        className="max-w-[920px] rounded-xl mb-0" />

                    <p className="font-poppins font-black lg:text-[25px] text-[20px] text-blue-school my-6 uppercase">{date.toUpperCase()}</p>

                    {/* ÉCOLES */}
                    {/* <div className="mb-8">
                    <p className="font-poppins font-black lg:text-[45px] text-[25px] text-blue-school mb-2 uppercase">{date.toUpperCase()}</p>
                        {
                            schools.map((school, i) => {
                            return (<div key={i}>
                                            <p className="inline-block font-poppins font-semibold text-white lg:text-[23px] text-[18px] uppercase">{school.name.toUpperCase()} - </p>
                                            <br className="lg:hidden block"/>
                                            <p className="inline-block font-poppins font-extrabold italic text-blue-school lg:text-[23px] text-[18px] uppercase">{school.city.toUpperCase()}</p>
                                        </div>)
                            })
                        }
                    </div> */}

                    <div className="mb-12 text-center mx-2 lg:mx-[10%]">
                    {
                        artist.bio.map((paragraph, i) => {
                          return (<p key={i} className="font-openSans text-[18px] text-blue-school text-justify mx-4">{paragraph}</p>)
                        })
                    }
                    </div>

                    {/* {(undefined !== sponsorLocalPath && sponsorLocalPath !== '') &&
                        <div className="mb-12">
                            <p className="font-poppins text-[18px] text-blue-school mb-6">En collaboration avec</p>
                            <DynamicStaticImage
                                localPath={sponsorLocalPath}
                                alt="Partenaire"
                                className="max-w-[150px] max-h-[82px]" />
                        </div>
                    } */}

                    {/* VIDEOS */}
                    <center>
                        <div className={`!relative px-6 my-24 grid z-10 !text-center ${youtubeVideoIds.length === 3 ? 'xl:grid-cols-3' : youtubeVideoIds.length === 2 ? 'xl:grid-cols-2 max-w-[66%]' : 'max-w-[33%]'}`}>
                            <div className="mx-2 mb-4">
                                {youtubeVideoIds && youtubeVideoIds[0]
                                  ? <div className="!relative pb-[56.25%]">
                                    <iframe
                                        id="1"
                                        title="Youtube 1"
                                        className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                                        src={`https://www.youtube.com/embed/${youtubeVideoIds[0]}`}
                                        allowFullScreen=""
                                        referrerPolicy="origin-when-cross-origin" />
                                </div>
                                  : null}
                            </div>
                            <div className="mx-4 mb-12">
                                {youtubeVideoIds && youtubeVideoIds[1]
                                  ? <div className="!relative pb-[56.25%]">
                                        <iframe
                                            id="2"
                                            title="Youtube 2"
                                            className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                                            src={`https://www.youtube.com/embed/${youtubeVideoIds[1]}`}
                                            allowFullScreen=""
                                            referrerPolicy="origin-when-cross-origin" />
                                    </div>
                                  : null}
                            </div>
                            <div className="mx-4 mb-12">
                            {youtubeVideoIds && youtubeVideoIds[2]
                              ? <div className="!relative pb-[56.25%]">
                                    <iframe
                                        id="3"
                                        title="Youtube 3"
                                        className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                                        src={`https://www.youtube.com/embed/${youtubeVideoIds[2]}`}
                                        allowFullScreen=""
                                        referrerPolicy="origin-when-cross-origin" />
                                </div>
                              : null}
                            </div>
                        </div>
                    </center>
                </center>
            </div>
        </div>
    </SchoolLayout>
  )
}
export default artistAtSchoolTemplate
